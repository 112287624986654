import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.css";

export const NotFound = () => {
  return (
    <section className="error-page section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-12">
            <div className="error-inner">
              <h1>
                404<span>Oop's sorry we can't find that page!</span>
              </h1>
              <p>
                Uh-oh! It seems you've ventured into uncharted territory. The
                page you seek might be elusive, perhaps hiding in another
                digital realm. Return to the safety of the homepage or navigate
                anew. If this persists, notify our vigilant web keepers.
                Apologies for any inconvenience; we're here to assist you on
                your journey
              </p>
              <form className="search-form">
                {/* <input placeholder="Search from Here" type="text"> */}
                <Link to="/">
                  <button style={{ border: "none" }}>
                    <div className="btn w-100 text-white font-weight-bold">
                      Back to Home
                    </div>
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
