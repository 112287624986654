import React, { useEffect, useRef } from "react";
import { Navbar } from "../Components/Navbar";
import { Main } from "../Components/Main";
import { About } from "../Components/About";
import { Details } from "../Components/Details";
import { Counter } from "../Components/Counter";
import { Owl_Corousel } from "../Components/Owl_Corousel";
import { Footer } from "../Components/Footer";
import Clients from "../Components/Clients.js";
import { HeaderBar } from "../Components/HeaderBar";
import WOW from 'wowjs'

const Home = () => {
  const aboutRef = useRef(null);



  
  return (
    <div>
      {/* <HeaderBar /> */}
      <Main aboutRef={aboutRef}/>
      <About aboutRef={aboutRef}/>
      <Details />
      <Counter />
      <Clients />
      {/* <Owl_Corousel /> */}
      <div className="snackbars" id="form-output-global"></div>
    </div>
  );
};

export default Home;
