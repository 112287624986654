import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Footer } from "./Components/Footer";
import { Header } from "./Components/Header";
import { Navbar } from "./Components/Navbar";
import { AboutPage } from "./Pages/AboutPage";
import { Contact } from "./Pages/Contact";
import Home from "./Pages/Home";
import { NotFound } from "./Pages/NotFound";
import { Service } from "./Pages/Service";
import { useState } from "react";
import { Gallery } from "./Pages/Gallery";

function App() {
  const [navbarData, setNavbarData] = useState(null);
  return (
    <BrowserRouter>
      <Header setNavbarData={setNavbarData} />
      <Navbar navbarData={navbarData} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Service />} />
        {/* <Route path="/gallery" element={<Gallery />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
