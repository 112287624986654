import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/images/foreground-01-1600x310.png";
import img2 from "../assets/images/logo-default-200x34.png";
import img3 from "../assets/images/logopng (3).png";
import img4 from "../assets/images/background-01-1920x310.jpg";
import img5 from "../assets/images/background-01-3840x620.jpg";
import img6 from "../assets/images/foreground-01-3200x620.png";

export const Footer = () => {
  return (
    <div>
      {" "}
      <a
        className="section section-banner"
        href="https://www.templatemonster.com/intense-multipurpose-html-template.html"
        target="_blank"
        style={{
          backgroundImage: { img4 },
          WebkitImageSet: { img4 } + " 1x, " + { img5 } + " 2x",
        }}
      >
        <img
          src={img1}
          srcset={img1 + " 1x, " + img6 + " 2x"}
          alt="aaaAAA"
          width="1600"
          height="310"
        />
      </a>
      <footer className="section footer-classic section-sm">
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-3 col-sm-3 wow fadeInLeft">
              <p className="footer-classic-title">About</p>
              <a className="brand" href="index.html">
                <img
                  className="brand-logo-dark"
                  src={img2}
                  alt=""
                  width="100"
                  height="17"
                />
                <div className="w-50">
                  <img className="brand-logo-light " src={img3} />
                </div>
              </a>
              <p className="footer-classic-description offset-top-0 offset-right-25 ">
                СonsultBiz provides a full range of business consulting &
                advisory services to small, medium, and international companies
                worldwide.
              </p>
            </div>
            <div className="col-lg-3 col-sm-3 wow fadeInUp">
              <p className="footer-classic-title">Services</p>

              <ul>
                <li>Creating Banners</li>
                <li>Advertising Campaigns</li>
                <li>Branding</li>
                <li>Social media marketing</li>
                <li>Web Design</li>
              </ul>
            </div>

            <div className="col-lg-3 col-sm-2 wow fadeInUp">
              <p className="footer-classic-title">Quick Links</p>
              <ul className="footer-classic-nav-list">
                <li onClick={()=>{window.scrollTo(0,0)}}>
                  <Link to="/home">Home</Link>
                </li>
                <li onClick={()=>{window.scrollTo(0,0)}}>
                  <Link to="/about">About</Link>
                </li>
                <li onClick={()=>{window.scrollTo(0,0)}}>
                  <Link to="/services">Services</Link>
                </li>
                <li onClick={()=>{window.scrollTo(0,0)}}>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-4 wow fadeInUp">
              <p className="footer-classic-title">Open Hours</p>

              <p>We are open in</p>
              <ul>
                <li className="w-100 d-flex justify-content-between">
                  Mon-Thu:
                  <span className="d-inline-block offset-left-10 text-white text-right">
                    9:30 AM - 9:00 PM
                  </span>
                </li>
                <li className="w-100 d-flex justify-content-between">
                  Fri:
                  <span className="d-inline-block offset-left-10 text-white text-right">
                    10:00 AM - 9:00 PM
                  </span>
                </li>
                <li className="w-100 d-flex justify-content-between">
                  Sat:
                  <span className="d-inline-block offset-left-10 text-white text-right">
                    10:00 AM - 3:00 PM
                  </span>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-4 wow fadeInLeft" data-wow-delay=".2s">
              <p className="footer-classic-title">newsletter</p>
              <form
                className="rd-mailform text-left footer-classic-subscribe-form"
                data-form-output="form-output-global"
                data-form-type="contact"
                method="post"
                action="bat/rd-mailform.php"
              >
                <div className="form-wrap">
                  <label className="form-label" for="subscribe-email">
                    Your Email Address
                  </label>
                  <input
                    className="form-input"
                    id="subscribe-email"
                    type="email"
                    name="email"
                    data-constraints="@Email @Required"
                  />
                </div>
                <div className="form-button group-sm text-center text-lg-left">
                  <button
                    className="button button-primary button-circle"
                    type="submit"
                  >
                    sign up
                  </button>
                </div>
              </form>
              <p>
                Be the first to find out about our latest news, updates, and
                special offers.
              </p>
            </div> */}
          </div>
        </div>
        <div className="container wow fadeInUp" data-wow-delay=".4s">
          <div className="footer-classic-aside justify-content-center">
            <p className="rights ">
              <span>&copy;&nbsp;</span>
              <span className="copyright-year"></span> All Rights Reserved.
              Design & Develop by{" "}
              <a href="https://www.buildzone.online" target="_blank">
                <b>BuildZone IT Solutions</b>
              </a>
            </p>
            {/* <ul className="social-links">
              <li>
                <a className="fa fa-linkedin" href="#"></a>
              </li>
              <li>
                <a className="fa fa fa-twitter" href="#"></a>
              </li>
              <li>
                <a className="fa fa-facebook" href="#"></a>
              </li>
              <li>
                <a className="fa fa-instagram" href="#"></a>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </div>
  );
};
