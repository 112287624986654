import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import './assets/css/style.css';
import "./assets/css/style.css";
import "./assets/css/fonts.css";
import ScrollToTopButton from "./Components/ScrollToTopBtn";
import { WhatsAppBtn } from "./Components/WhatsAppBtn";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
    <div
      style={{
        position: "fixed",
        bottom: "50px",
        right: "48px",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        scale: "1.25",
        transition: "0.3s",
        borderRadius: "100px",
        gap: "20px",
      }}
    >
      <ScrollToTopButton />
      <WhatsAppBtn />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
