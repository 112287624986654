import React from "react";
import img1 from "../assets/images/Advertising.jpg";
import WOW from 'wowjs';
import { useEffect } from "react";

export const About = ({aboutRef}) => {
  useEffect(()=>{
    const wow = new WOW.WOW();
    wow.init();
  },[])
  return (
    <div ref={aboutRef}>
      <section className="section section-sm position-relative" id="about">
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-6">
              <div
                className="block-decorate-img wow fadeInLeft "
                data-wow-delay=".2s"
              >
                <img
                  src={img1}
                  alt=""
                  width="570"
                  height="351"
                  style={{
                    borderEndStartRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="block-sm offset-top-45">
                <div
                  className="section-name wow fadeInRight"
                  data-wow-delay=".2s"
                >
                  About us
                </div>
                <h3
                  className="wow fadeInLeft text-capitalize"
                  data-wow-delay=".3s"
                >
                  What We<span className="text-primary"> Do</span>
                </h3>
                <p className="offset-xl-40 wow fadeInUp" data-wow-delay=".4s">
                  Small or big, your business will love our financial help and
                  business consultations! We are happy when our clients are
                  too... Actually, this is quite simple to achieve - because
                  each time we help them in sorting out different accounting
                  intricacies or save the day before filing the taxes, they are
                  happy indeed! And so are we!
                </p>
                <p
                  className="default-letter-spacing font-weight-bold text-gray-dark wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  We have over fifteen years of successful experience in
                  financial sphere in the US business market.
                </p>
                <a
                  className="button-width-190 button-primary button-circle button-lg button offset-top-30"
                  href="#"
                >
                  read more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="decor-text decor-text-1">ABOUT</div>
      </section>
    </div>
  );
};
