import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { Menu } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img from "../assets/images/logopng (3).png";
import imgN from "../assets/images/logoN.png";

export const Navbar = ({ navbarData }) => {
  const componentRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const items = [
    {
      key: "home",
      label: "Home",
      path: "/home",
    },
    {
      key: "about",
      label: "About",
      path: "/about",
    },
    {
      key: "services",
      label: "Services",
      path: "/services",
    },
    // {
    //   key:"gallery",
    //   label:"Gallery",
    //   path:"/gallery",
    // },
    {
      key: "contacts",
      label: "Contacts",
      path: "/contact",
    },
  ];

  // useEffect(()=>{
  //   if()
  // },[location])

  useEffect(() => {
    console.log(showMenu);
  }, [showMenu]);

  const toggleMenu = () => {
    console.log("toggleMenu");
    setShowMenu(!showMenu);
  };

  const closeOnMobile = () => {
    window.scrollTo(0,0)
    console.log("closeOnMobile");
    if (window.innerWidth <= 1150) {
      console.log("closeOnMobile : YES");
      setShowMenu(false);
    }
  };


  return (
    <header
      className={navbarData ? "header" : "headerN"}
      style={{ position: navbarData ? "fixed" : "relative", zIndex:"999" }}
    >
      <div ref={componentRef}></div>
      <nav className="nav container ">
        <NavLink to="/" className="nav__logo d-flex align-items-center" style={{ height: "100%" }}>
          <img src={navbarData ? img : img} alt="logo" />
        </NavLink>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""} `}
          id="nav__menu"
        >
          <ul className="nav__list d-flex align-items-center">
            {items.map((item) => {
              return (
                <li>
                  <NavLink
                    key={item.key}
                    to={item.path}
                    className={`${navbarData ? "nav__link" : "nav__linkN"}`}
                    onClick={closeOnMobile}
                  >
                    {item.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </nav>
    </header>
  );
};


