import React from "react";
import img1 from "../assets/images/nameboard.jpg";
import img2 from "../assets/images/mug.jpg";
import img3 from "../assets/images/wrap.jpg";
import img4 from "../assets/images/social.jpg";
import img5 from "../assets/images/intro-bg-1.jpg";
import Pricing from "../Components/Clients.js";
import { useEffect } from "react";
import WOW from 'wowjs';

export const Service = () => {
  useEffect(()=>{
    const wow = new WOW.WOW();
    wow.init();
  },[])
  return (
    <div>
      <section className="section section-intro context-dark">
        <div
          className="intro-bg "
          style={{
            backgroundImage: "url(" + img5 + ")",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center">
              <h1 className="font-weight-bold wow fadeInLeft">Our Services</h1>
              <p className="intro-description wow fadeInRight">
                Feel free to learn more about our team and company on this page.
                We are always happy to help you!
              </p>
            </div>
          </div>
        </div>
      </section>
      <div>
        <section className="section section-sm position-relative" id="about">
          <div className="container">
            <div className="row ">
              <div className="col-lg-6 ">
                <div className="block-sm ">
                  <h3 className="wow fadeInLeft text-capitalize">
                    Indoor<span className="text-primary"> Branding</span>
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <ul
                        className="offset-xl-40 wow "
                        data-wow-delay=".4s"
                        itemType="disc"
                      >
                        <li className="wow slideInRight">Wall Signs</li>
                        <li className="wow slideInRight">Showroom Brandings</li>
                        <li className="wow slideInRight">Display Racks</li>
                        <li className="wow slideInRight">Store Racks</li>
                        <li className="wow slideInRight">Stall Brandings</li>
                        <li className="wow slideInRight">Sticker Brandings</li>
                        <li className="wow slideInRight">Crystal Panel</li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="offset-xl-40 wow " data-wow-delay=".4s">
                        <li className="wow slideInRight">Interior Design</li>
                        <li className="wow slideInRight">Mug Printing</li>
                        <li className="wow slideInRight">Plaques, Badges</li>
                        <li className="wow slideInRight">Business Cards</li>
                        <li className="wow slideInRight">Leaflets Certificates</li>
                        <li className="wow slideInRight">Gondola Brandings</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={img2}
                  alt=""
                  width="570"
                  height="351"
                  style={{
                    borderEndStartRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-6">
                <img
                  src={img3}
                  alt=""
                  width="570"
                  height="351"
                  style={{
                    borderEndStartRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                />
              </div>
              <div className="col-lg-6">
                <div className="block-sm">
                  <h3 className="wow fadeInLeft text-capitalize">
                    Outdoor<span className="text-primary"> Branding</span>
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <ul className="offset-xl-40 wow" data-wow-delay=".4s">
                        <li className="wow slideInRight">Sign Boards</li>
                        <li className="wow slideInRight">Hoardings</li>
                        <li className="wow slideInRight">Flex Boards</li>
                        <li className="wow slideInRight">Flex Light Boards</li>
                        <li className="wow slideInRight">Plastic Light Boards</li>
                        <li className="wow slideInRight">Pylon Signs</li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="offset-xl-40 wow" data-wow-delay=".4s">
                        <li className="wow slideInRight">Stainless Steel Signs</li>
                        <li className="wow slideInRight">Wall Paintings</li>
                        <li className="wow slideInRight">Vehicle Brandings</li>
                        <li className="wow slideInRight">Event and Promotion</li>
                        <li className="wow slideInRight">Dealer Boards</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="block-sm">
                  <h3 className="wow fadeInLeft text-capitalize">
                    Digital<span className="text-primary"> Marketing</span>
                  </h3>
                  <ul className="offset-xl-40 wow " data-wow-delay=".4s">
                    <li className="wow slideInRight">Facebook Marketing</li>
                    <li className="wow slideInRight">Website Enhancement</li>
                    <li className="wow slideInRight">Social Media Marketing</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={img4}
                  alt=""
                  width="570"
                  height="351"
                  style={{
                    borderEndStartRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Pricing />
    </div>
  );
};
