import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "./WhatsAppBtn.css";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <div
          className="floating-whatsapp"
          style={{
            zIndex: "99",
            backgroundColor: "rgb(243, 109, 22)",
            // boxShadow: "1px 1px 10px rgba(0,0,0,0.05)",
            scale: "1.1",

            transition: "0.3s",
            borderRadius: "100px",
          }}
        >
          <a
            id="back-to-top"
            href="#"
            className="btn floating-icon"
            style={{ border: "none" }}
            role="button"
          >
            <FontAwesomeIcon icon={faArrowUp} color="white" />
          </a>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
