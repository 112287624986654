import React from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

export const HeaderBar = ({ setNavbarData }) => {
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      setNavbarData(false);
    } else {
      setNavbarData(true);
    }
  }, [inView]);
  return (
    <div
      className="rd-navbar-aside-outer rd-navbar-collapse bg-gray-dark p-2 "
      ref={ref}
    >
      <div className="rd-navbar-aside d-flex justify-content-between container  ">
        <div className="row w-100">
          <div className="col-lg-6 d-flex justify-content-center">
            <ul className="list-inline navbar-contact-list mr-auto d-flex align-items-center ">
              <li>
                <div className="unit unit-spacing-xs align-items-center">
                  <div className="unit-left">
                    <span className="icon text-middle fa-phone"></span>
                  </div>
                  <div className="unit-body">
                    <Link to="tel:0740376676">+94740376676</Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="unit unit-spacing-xs align-items-center">
                  <div className="unit-left">
                    <span className="icon text-middle fa-envelope"></span>
                  </div>
                  <div className="unit-body">
                    <Link to="mailto:laserholdingsmail@gmail.com">
                      laserholdingsmail@gmail.com
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="unit unit-spacing-xs align-items-center">
                  <div className="unit-left">
                    <span className="icon text-middle fa-map-marker"></span>
                  </div>
                  <div className="unit-body">
                    <Link to="#">Higgashena, Kuruwita.</Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <ul className="social-links">
              <li>
                <Link
                  className="icon icon-sm icon-circle icon-circle-md icon-bg-white fa-whatsapp"
                  to="https://wa.me/94740376676"
                  target="_blank"
                ></Link>
              </li>
              <li>
                <Link
                  className="icon icon-sm icon-circle icon-circle-md icon-bg-white fa-facebook"
                  to="https://web.facebook.com/laserholdings/?_rdc=1&_rdr"
                  target="_blank"
                ></Link>
              </li>

              <li>
                <Link
                  className="icon icon-sm icon-circle icon-circle-md icon-bg-white fa-phone"
                  to="tel:0740376676"
                ></Link>
              </li>
              <li>
                <Link
                  className="icon icon-sm icon-circle icon-circle-md icon-bg-white fa-globe"
                  to="https://www.laserholdings.com"
                ></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
