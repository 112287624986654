import React, { useEffect } from "react";
import img1 from "../assets/images/bg-1-1700x803.jpg";
import img2 from "../assets/images/parallax-item-2-276x343.png";
import img3 from "../assets/images/parallax-item-3-153x144.png";
import img4 from "../assets/images/parallax-item-4-69x74.png";
import img5 from "../assets/images/parallax-item-5-72x75.png";
import img6 from "../assets/images/parallax-item-6-45x54.png";
import img7 from "../assets/images/parallax-item-1-563x532.png";
import banner from "../assets/images/bannermain.jpg";
import WOW from 'wowjs';


export const Main = ({aboutRef}) => {

  useEffect(()=>{
    const wow = new WOW.WOW();
    wow.init();
  },[])
  
  return (
    <div>
      <div
        className="section main-section parallax-scene-js "
        style={{
          backgroundImage: "url(" + banner + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-12">
              <div className="text-center text-xl-left">
                <h1
                  className="text-white text-xl-center wow slideInRight"
                  data-wow-delay=".3s"
                >
                  <span className="align-top offset-top-30 d-inline-block font-weight-light prefix-text">
                    the
                  </span>
                  <span className="big font-weight-bold d-inline-flex offset-right-170">
                    best
                  </span>
                  <span className="biggest d-block d-xl-flex font-weight-bold">
                    Solutions.
                  </span>
                </h1>
                <div className="decorated-subtitle text-italic text-white wow slideInLeft">
                  Fresh Ideas for Your Business
                </div>
              </div>
            </div>
            <div
              className="col-12 text-center offset-top-75"
              data-wow-delay=".2s"
            >
              <div
                className="button-way-point d-inline-block text-center d-inline-flex flex-column justify-content-center"
                onClick={()=>{
                  console.log(aboutRef.current.offsetTop)
                  window.scrollTo({
                    top: aboutRef.current.offsetTop*0.85 ,
                    behavior: "smooth",              
                  })
                }}
                data-custom-scroll-to="about"
              >
                <span className="fa-chevron-down"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="decorate-layer">
          <div className="layer-1">
            <div className="layer" data-depth=".20">
              <img src={img7} alt="" width="563" height="266" />
            </div>
          </div>
          <div className="layer-2">
            <div className="layer" data-depth=".30">
              <img src={img2} alt="" width="276" height="171" />
            </div>
          </div>
          <div className="layer-3">
            <div className="layer" data-depth=".40">
              <img src={img3} alt="" width="153" height="72" />
            </div>
          </div>
          <div className="layer-4">
            <div className="layer" data-depth=".20">
              <img src={img4} alt="" width="69" height="37" />
            </div>
          </div>
          <div className="layer-5">
            <div className="layer" data-depth=".40">
              <img src={img5} alt="" width="72" height="37" />
            </div>
          </div>
          <div className="layer-6">
            <div className="layer" data-depth=".30">
              <img src={img6} alt="" width="45" height="27" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
