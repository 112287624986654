import React from "react";
import { useRef } from "react";
import { useInView } from "react-intersection-observer";

import img3 from "../assets/images/foreground-02-3200x120.png";
import img2 from "../assets/images/foreground-02-1600x60.png";
import { useEffect } from "react";

export const HeadPoster = ({ setNavbarData }) => {
  const { ref, inView, entry } = useInView();
  useEffect(() => {
    if (inView) {
      console.log("transparent");
    } else {
      console.log("white");
    }
  }, [inView]);
  return (
    <div>
      <a
        className="section section-banner d-none d-xl-block"
        href="https://www.templatemonster.com/intense-multipurpose-html-template.html"
        target="_blank"
        style={{
          backgroundImage: "url(images/background-02-1920x60.jpg)",
          WebkitImageSet:
            "url(images/background-02-1920x60.jpg) 1x, url(images/background-02-3840x120.jpg) 2x",
        }}
      >
        <img
          src={img2}
          srcset={img2 + " 1x, " + img3 + " 2x"}
          alt=""
          width="1600"
          height="310"
        />
      </a>
      <div></div>
    </div>
  );
};
