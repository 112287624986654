import React from "react";
import img from "../assets/images/bg-image-3-1700x883.jpg";
import "./Clients.css";
import img1 from "../assets/images/logo/4.jpg";
import img2 from "../assets/images/logo/2.webp";
import img3 from "../assets/images/logo/3.webp";
import img4 from "../assets/images/logo/4.avif";
import img5 from "../assets/images/logo/6.png";
import img6 from "../assets/images/logo/7.png";
import img7 from "../assets/images/logo/8.png";
import img8 from "../assets/images/logo/9.jpg";
import img9 from "../assets/images/logo/50.png";
import img10 from "../assets/images/logo/11.png";
import img11 from "../assets/images/logo/12.svg";
import img12 from "../assets/images/logo/14.webp";
import img13 from "../assets/images/logo/15.webp";
import img14 from "../assets/images/logo/16.png";
import img15 from "../assets/images/logo/61.png";
import img16 from "../assets/images/logo/18.png";
import img17 from "../assets/images/logo/64.png";
import img18 from "../assets/images/logo/20.webp";
import img19 from "../assets/images/logo/21.jpg";
import img20 from "../assets/images/logo/66.png";
import img21 from "../assets/images/logo/23.jpg";
import img22 from "../assets/images/logo/24.jpg";
import img23 from "../assets/images/logo/25.png";
import img24 from "../assets/images/logo/26.png";
import img25 from "../assets/images/logo/27.png";
import img26 from "../assets/images/logo/62.png";
import img27 from "../assets/images/logo/29.png";
import img28 from "../assets/images/logo/63.png";
import present from "../assets/images/wrap.png";
import img29 from "../assets/images/logo/31.png";
import img30 from "../assets/images/logo/60.png";
import img31 from "../assets/images/logo/65.png"; 
import img32 from "../assets/images/logo/59.png";

const Clients = () => {
  const logos = [
    img1,
    img2,
    img3,
    img29,
    img10,
    img11,
    img12,
    img13,
    img14,
    img21,
    img5,
    img6,
    img7,
    img8,
    img9,
    img22,      
    img23,
    img30,
    img32,
    img24,
    img25,
    img26,
    img27,
    img28,  
    img15,
    img16,
    img17,
    img18,
    img31,
    img19,
    img20,
  ];
  return (
    <div className="container">
      <section
        className="section section-md bg-xs-overlay"
        style={{
          background: { img } + " no-repeat center",
          backgroundSize: "cover",
        }}
      >
        <div className="">
          <div className="row row-50 justify-content-center">
            <div className="col-12 text-center col-md-10 col-lg-8">
              <div
                className="section-name wow fadeInRight"
                data-wow-delay=".2s"
              >
                Our Clients
              </div>
              <h3
                className="wow fadeInLeft text-capitalize"
                data-wow-delay=".3s"
              >
                Trusted by
                <span className="text-primary"> businesses worldwide</span>
              </h3>
              <p>
                Discover the success stories of our valued clients. We take
                pride in delivering exceptional service and results to
                businesses like yours. Learn more about the organizations that
                trust us.
              </p>
            </div>
          </div>
        </div>

        <div className="section-bg" id="clients">
          <div
            className="row no-gutters clients-wrap clearfix wow fadeInUp"
            style={{ visibility: "visible" }}
          >
            {logos.map((logo, index) => (
              <div className="col-lg-3 col-md-4 col-xs-6 popup" key={index}>
                <div
                  className="client-logo   shadow-lg "
                  style={{
                    margin: "10px",
                    borderWidth: "5px",
                    borderEndStartRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <img
                    src={logo}
                    className="img-fluid "
                    alt="Client Logo"
                    // style={{margin:"10px"}}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
