import React from "react";
// import img1 from '../assets/images/'
import { HeadPoster } from "./HeadPoster";
import { HeaderBar } from "./HeaderBar";
import { useState } from "react";

export const Header = ({ setNavbarData }) => {
  return (
    <div>
      <HeadPoster setNavbarData={setNavbarData} />
      <HeaderBar setNavbarData={setNavbarData} />
    </div>
  );
};
