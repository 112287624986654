import React from "react";
import "./WhatsAppBtn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export const WhatsAppBtn = () => {
  return (
    <div
      className="floating-whatsapp"
      style={{
        zIndex: "99",
        width: "100%",

        transition: "0.3s",
        borderRadius: "100px",
      }}
    >
      <div className="d-flex justify-content-center align-item-center floating-icon">
        <a href="https://wa.me/94740376676" target="_blank">
          <FontAwesomeIcon
            size="3x"
            icon={faWhatsapp}
            color="rgb(243, 109, 22)"
          />
        </a>
      </div>
    </div>
  );
};
