import React from "react";

export const Counter = () => {
  return (
    <div>
      <section className="section bg-image-2">
        <div className="container section-md">
          <div className="container row row-30 text-center w-100">
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="counter-classic">
                <div className="counter-classic-number">
                  <span className="icon-lg novi-icon offset-right-10 mercury-icon-time"></span>
                  <span className="counter text-white" data-speed="2000">
                    2017
                  </span>
                </div>
                <div className="counter-classic-title">
                  Year of Establishment
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="counter-classic">
                <div className="counter-classic-number">
                  <span className="icon-lg novi-icon offset-right-10 mercury-icon-folder"></span>
                  <span className="counter text-white" data-speed="2000">
                    500
                  </span>
                  <span className="symbol text-white">+</span>
                </div>
                <div className="counter-classic-title">Successful Projects</div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="counter-classic">
                <div className="counter-classic-number">
                  <span className="icon-lg novi-icon offset-right-10 mercury-icon-users"></span>
                  <span className="counter text-white" data-speed="2000">
                    100
                  </span>
                  <span className="symbol text-white">+</span>
                </div>
                <div className="counter-classic-title">Global Partners</div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12">
              <div className="counter-classic">
                <div className="counter-classic-number">
                  <span className="icon-lg novi-icon offset-right-10 mercury-icon-group"></span>
                  <span className="counter text-white" data-speed="2000">
                    50
                  </span>
                  <span className="symbol text-white">+</span>
                </div>
                <div className="counter-classic-title">Our Crew</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
