import React from "react";
import img1 from "../assets/images/adver.webp.jpg";
import img3 from "../assets/images/team-classic-1-370x397.jpg";
import img4 from "../assets/images/intro-bg-1.jpg";
import { useEffect } from "react";
import WOW from "wowjs";

export const AboutPage = () => {
  useEffect(()=>{
    const wow = new WOW.WOW();
    wow.init();
  },[])
  return (
    <div>
      <section className="section section-intro context-dark">
        <div
          className="intro-bg "
          style={{
            backgroundImage: "url(" + img4 + ")",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center">
              <h1 className="font-weight-bold wow fadeInLeft">About Us</h1>
              <p className="intro-description wow fadeInRight">
                Feel free to learn more about our team and company on this page.
                We are always happy to help you!
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About page about section--> */}
      <section className="section section-md">
        <div className="container">
          <div className="row row-40 justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="offset-top-45 offset-lg-right-45">
                <div
                  className="section-name wow fadeInRight"
                  data-wow-delay=".2s"
                >
                  About us
                </div>
                <h3
                  className="wow fadeInLeft text-capitalize"
                  data-wow-delay=".3s"
                >
                  A Few Words<span className="text-primary"> about us</span>
                </h3>
                <p
                  className="font-weight-bold text-gray-dark wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  fermentum nulla ac tincidunt malesuada. Sed volutpat semper
                  elit quis pharetra. Etiam sodales a sem vitae fermentum.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Sapien eget mi proin sed libero enim sed faucibus turpis.
                  Placerat in egestas erat imperdiet sed euismod nisi. Vulputate
                  eu scelerisque felis imperdiet. Lorem donec massa sapien
                  faucibus. Volutpat diam.
                </p>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Adipiscing enim eu turpis egestas pretium aenean pharetra
                  magna. Nullam non nisi est sit amet facilisis magna etiam. Nec
                  feugiat in fermentum posuere urna nec.
                </p>
                <div className="offset-top-20">
                  {/* <!--Linear progress bar--> */}
                  <article className="progress-linear">
                    <div className="progress-header progress-header-simple">
                      <p>Management</p>
                      <span className="progress-value">85</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </article>
                  {/* <!--Linear progress bar--> */}
                  <article className="progress-linear">
                    <div className="progress-header progress-header-simple">
                      <p>Marketing</p>
                      <span className="progress-value">45</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </article>
                  {/* <!--Linear progress bar--> */}
                  <article className="progress-linear">
                    <div className="progress-header progress-header-simple">
                      <p>Analysis</p>
                      <span className="progress-value">90</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-10 col-12">
              <div
                className="block-decorate-img wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <img
                  src={img1}
                  alt=""
                  width="570"
                  height="351"
                  style={{
                    borderEndStartRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Our team--> */}
      <section className="section section-md">
        <div className="container">
          <div className="row row-50 justify-content-center">
            <div className="col-md col-12 text-center">
              <div
                className="section-name wow fadeInRight"
                data-wow-delay=".2s"
              >
                People Behind Our Success
              </div>
              <h3
                className="wow fadeInLeft text-capitalize"
                data-wow-delay=".3s"
              >
                Meet Our<span className="text-primary"> Team</span>
              </h3>
              <p className="block-675">
                Mi proin sed libero enim sed faucibus. Metus dictum at tempor
                commodo. Viverra justo nec ultrices dui sapien eget mi. Eget
                felis eget nunc lobortis.
              </p>
            </div>
          </div>
          <div className="row row-50 justify-content-center">
            <div
              className="col-xl-4 col-sm-6 col-10 wow fadeInLeft"
              data-wow-delay=".3s"
            >
              <div className="team-classic-wrap">
                <div
                  className="team-classic-img"
                  style={{
                    borderEndStartRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <img src={img3} alt="" width="370" height="198" />
                </div>
                <div className="block-320 text-center">
                  <h4 className="font-weight-bold">William Johnson</h4>
                  <span className="d-block">CEO, Founder</span>
                  <p>
                    Placerat duis ultricies lacus sed turpis tincidunt id
                    aliquet. Et pharetra pharetra massa massa ultricies.
                  </p>
                  <hr className="offset-top-40" />
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-sm-6 col-10 wow fadeInUp"
              data-wow-delay=".3s"
            >
              <div className="team-classic-wrap">
                <div
                  className="team-classic-img"
                  style={{
                    borderEndStartRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <img src={img3} alt="" width="370" height="198" />
                </div>
                <div className="block-320 text-center">
                  <h4 className="font-weight-bold">Jane McMillan</h4>
                  <span className="d-block">Business Advisor</span>
                  <p>
                    Ut morbi tincidunt augue interdum velit. Sed euismod nisi
                    porta lorem nisl rhoncus mattis rhoncus urna sed.
                  </p>
                  <hr className="offset-top-40" />
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-sm-6 col-10 wow fadeInRight"
              data-wow-delay=".3s"
            >
              <div className="team-classic-wrap">
                <div
                  className="team-classic-img"
                  style={{
                    borderEndStartRadius: "25px",
                    borderTopRightRadius: "25px",
                  }}
                >
                  <img src={img3} alt="" width="370" height="198" />
                </div>
                <div className="block-320 text-center">
                  <h4 className="font-weight-bold">Robert Smith</h4>
                  <span className="d-block">Marketing Manager</span>
                  <p>
                    Vitae aliquet nec ullamcorper sit amet risus nullam eget
                    felis. Euismod lacinia at quis risus sed vulputate.
                  </p>
                  <hr className="offset-top-40" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Brands--> */}

      

      {/* </div> */}
      <div className="snackbars" id="form-output-global"></div>
    </div>
  );
};
