import React from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import img4 from "../assets/images/intro-bg-1.jpg";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import WOW from "wowjs";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

export const Contact = () => {

  
  function onChange(value) {
    setRobotVal(value);
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();
    await emailjs
      .sendForm("service_pecf8j8", "template_wxoidcn", form.current, {
        publicKey: "Fe0yvOtQokEvzxfIH",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          alert("Message Sent Successfully!");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const [robotVal, setRobotVal] = React.useState(false);
  const form = useRef();

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
      <div className="">
        <section className="section section-intro context-dark">
          <div
            className="intro-bg "
            style={{
              backgroundImage: "url(" + img4 + ")",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 text-center">
                <h1 className="font-weight-bold wow fadeInLeft">Contact Us</h1>
                <p className="intro-description wow fadeInRight">
                  Feel free to learn more about our team and company on this
                  page. We are always happy to help you!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-md">
          <div className="container">
            <div className="row shadow wow slideInUp">
              <div className="col-lg-4 h-100 d-flex align-items-center my-2">
                <div className="row d-flex justify-content-between align-items-center w-100 h-100">
                  <div className="col-3">
                    <div className="contact-icon d-flex justify-content-center align-items-center ">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ scale: "2", color: "#f36d16" }}
                      />
                    </div>
                  </div>
                  <div className=" col-9 ">
                    <div className="d-flex flex-column justify-content-left align-items-left">
                      <h4>Address</h4>
                      Higgashena, Kuruwita, Sri Lanka, 70500
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 h-100 d-flex align-items-center my-2">
                <div className="row d-flex justify-content-between align-items-center w-100 h-100">
                  <div className="col-3">
                    <div className="contact-icon d-flex justify-content-center align-items-center ">
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ scale: "2", color: "#f36d16" }}
                      />
                    </div>
                  </div>
                  <div className=" col-9">
                    <div className="d-flex flex-column justify-content-left align-items-left">
                      <h4>Telephone</h4>+94 74 037 6676
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 h-100 d-flex align-items-center my-2">
                <div className="row d-flex justify-content-between align-items-center w-100 h-100 ">
                  <div className="col-3">
                    <div className="contact-icon d-flex justify-content-center align-items-center ">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ scale: "2", color: "#f36d16" }}
                      />
                    </div>
                  </div>
                  <div className=" col-9 ">
                    <div className="d-flex flex-column justify-content-left align-items-left">
                      <h4>Email</h4>laserholdingsmail@gmail.com
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <div className="row">
              <div className="col-xl-6 col-md-6 wow slideInLeft">
                <div className="shadow p-4 h-100 w-100">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.882244981955!2d80.35476127578623!3d6.784182120077494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3bba2f0968335%3A0xd88e64919224632d!2sLaser%20Holdings%20(pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1709646272549!5m2!1sen!2slk"
                    // width="600" height="450"
                    style={{ border: 0, width: "100%", height: "100%" }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-xl-6 col-md-6 wow slideInRight">
                <div className="shadow p-4">
                  <form onSubmit={handleSubmit} ref={form} >
                    <div className="mb-3">
                      {/* <label for="exampleInputPassword1" className="form-label"> */}
                      Name
                      {/* </label> */}
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        onFocus={(e) => {
                          e.target.style.boxShadow =
                            "0 0 0 0.25rem rgba(243, 109, 22, 0.25)";
                          e.target.style.border = "2px solid #f36d16";
                        }}
                        onBlur={(e) => {
                          e.target.style.boxShadow = "";
                          e.target.style.border = "1px solid #ced4da";
                        }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }
                        }
                      />
                    </div>
                    <div className="mb-3">
                      {/* <label for="exampleInputEmail1" className="form-label"> */}
                      Email address
                      {/* </label> */}
                      <input
                       name="email"
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onFocus={(e) => {
                          e.target.style.boxShadow =
                            "0 0 0 0.25rem rgba(243, 109, 22, 0.25)";
                          e.target.style.border = "2px solid #f36d16";
                        }}
                        onBlur={(e) => {
                          e.target.style.boxShadow = "";
                          e.target.style.border = "1px solid #ced4da";
                        }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }
                        }
                      />
                    </div>
                    <div className="mb-3">
                      {/* <label for="exampleInputPassword1" className="form-label"> */}
                      Subject
                      {/* </label> */}
                      <input
                        name="subject"
                        type="name"
                        className="form-control"
                        id="exampleInputName1"
                        onFocus={(e) => {
                          e.target.style.boxShadow =
                            "0 0 0 0.25rem rgba(243, 109, 22, 0.25)";
                          e.target.style.border = "2px solid #f36d16";
                        }}
                        onBlur={(e) => {
                          e.target.style.boxShadow = "";
                          e.target.style.border = "1px solid #ced4da";
                        }}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }
                        }
                      />
                    </div>
                    <div className="mb-3">
                      {/* <label for="exampleInputPassword1" className="form-label"> */}
                      Message
                      {/* </label> */}
                      <textarea
                        name="message"
                        rows={3}
                        type="name"
                        className="form-control"
                        id="exampleInputName1"
                        onFocus={(e) => {
                          e.target.style.boxShadow =
                            "0 0 0 0.25rem rgba(243, 109, 22, 0.25)";
                          e.target.style.border = "2px solid #f36d16";
                        }}
                        onBlur={(e) => {
                          e.target.style.boxShadow = "";
                          e.target.style.border = "1px solid #ced4da";
                        }}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }
                        }
                      />
                    </div>

                    <div className="d-flex justify-content-between">
                      <ReCAPTCHA
                        sitekey="6LdKAJYpAAAAAPzePkwdzXxSPOSn0zNZpVOWfnnQ"
                        onChange={onChange}
                      />
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                          (!robotVal) || (name === "") || (email === "") || (subject === "") || (message === "")
                        }
                        value="send"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="snackbars" id="form-output-global"></div>
    </div>
  );
};
