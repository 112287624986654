import React from "react";
import img1 from "../assets/images/home-2-636x480.jpg";

export const Details = () => {
  return (
    <div>
      <section className="section custom-section position-relative section-md">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-7">
              <div className="section-name wow fadeInRight">
                Elevate Your Brand with Us
              </div>
              <h3
                className="text-capitalize devider-left wow fadeInLeft"
                data-wow-delay=".2s"
              >
                Why Brands
                <span className="text-primary"> Choose Our Services</span>
              </h3>
              <p>
                With a rich history of crafting successful advertising
                campaigns, we bring valuable expertise to our clients. Here are
                some compelling reasons why brands worldwide choose to partner
                with us.
              </p>
              <div className="row row-15">
                <div className="col-xl-6 wow fadeInUp" data-wow-delay=".2s">
                  <div className="box-default">
                    <div className="box-default-title">Creative Campaigns</div>
                    <p className="box-default-description">
                      Our services are centered around imaginative and effective
                      advertising campaigns.
                    </p>
                    <span className="box-default-icon novi-icon icon-lg mercury-icon-lightbulb-gears"></span>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInUp" data-wow-delay=".3s">
                  <div className="box-default">
                    <div className="box-default-title">Strategic Branding</div>
                    <p className="box-default-description">
                      AdvertiseBiz strategically shapes your brand identity,
                      ensuring a compelling presence in the market.
                    </p>
                    <span className="box-default-icon novi-icon icon-lg mercury-icon-target-2"></span>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInUp" data-wow-delay=".4s">
                  <div className="box-default">
                    <div className="box-default-title">
                      Expert Creative Team
                    </div>
                    <p className="box-default-description">
                      Our team comprises skilled creatives with over a decade of
                      experience in the advertising industry.
                    </p>
                    <span className="box-default-icon novi-icon icon-lg mercury-icon-user"></span>
                  </div>
                </div>
                <div className="col-xl-6 wow fadeInUp" data-wow-delay=".5s">
                  <div className="box-default">
                    <div className="box-default-title">
                      Innovative Strategies
                    </div>
                    <p className="box-default-description">
                      Every project involves innovative approaches to address
                      advertising challenges and drive success.
                    </p>
                    <span className="box-default-icon novi-icon icon-lg mercury-icon-partners"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-5 wow ">
              <div className=" " data-wow-delay=".5s">
                <img
                  src={img1}
                  alt=""
                  width="636"
                  height="240"
                  style={{
                    borderEndStartRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="decor-text decor-text-2 wow fadeInUp"
          data-wow-delay=".3s"
        >
          features
        </div>
      </section>
    </div>
  );
};
